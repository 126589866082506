<template>
  <a-modal
    title="设置抽成比例"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 排序 -->
            <a-form-item label="抽成比例" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入"
                :min="0"
                :max="1"
                :step="0.01"
                style="width: 50%; min-width: 100px"
                v-decorator="['agentPlatformRatio', { rules: [{ required: true, message: '请输入抽成比例！' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { storeAdminSetAgentPlatRatio } from '@/api/modular/mallLiving/report'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      id: '',
    }
  },

  methods: {
    // 初始化方法
    edit(record) {
      const { id, agentPlatformRatio = 0 } = record || {}
      this.visible = true
      this.id = id
      this.$nextTick(() => {
        this.form.setFieldsValue({
          agentPlatformRatio,
        })
      })
    },
    //提交操作
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          storeAdminSetAgentPlatRatio({ id: this.id, ...values }).then((res) => {
            if (res.success) {
              this.$message.success('设置抽成比例成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },

    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
